import styled from "@emotion/styled/macro";
import { MQ, typographyToCss } from "../../styles/helpers";
import { xxlTheme } from "../../styles/xxl-theme";
import { css } from "@emotion/react";
import { gap } from "../Common/util";

const typography = {
  topTextTypography: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 20,
    lineHeight: 1.3,
    letterSpacing: -0.15,
    whiteSpace: "nowrap",
    textAlign: "center",
    tablet: {
      fontSize: 24,
    },
    desktop: {
      fontSize: 28,
    },
  },
  smallTextTypography: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    lineHeight: 1.2,
    letterSpacing: -0.15,
    whiteSpace: "nowrap",
    textAlign: "center",
  },
};

const { topTextTypography, smallTextTypography } = typography;

const { spaces, colors } = xxlTheme;

export const UspList = styled.ul`
  display: flex;
  column-gap: ${gap.mobile};
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  & * {
    box-sizing: border-box;
  }

  ${MQ("laptop")} {
    column-gap: ${gap.mobile};
  }
`;

export const UspItem = styled.li`
  width: 100%;
  height: 110px;
  flex-grow: 1;
  box-sizing: border-box;

  ${MQ("tablet")} {
    height: 158px;
  }
`;

export const TopText = styled.span`
  ${typographyToCss(topTextTypography)};
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
  margin-bottom: ${spaces.mini};
  padding: 0 ${spaces.regular};
  overflow: hidden;

  &:only-child {
    margin-bottom: 0;
  }
`;

export const SmallText = styled.span`
  ${typographyToCss(smallTextTypography)};
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
  padding: 0 ${spaces.regular};
  overflow: hidden;
`;

const commonLinkStyles = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${spaces.large} 0;
  overflow: hidden;
  text-decoration: none;
  border: 1px solid ${colors.xxlMediumGrey};
  background-color: ${colors.xxlWhite};
`;

export const Link = styled.a`
  ${commonLinkStyles};
  transition: all 0.2s ease;

  &:hover {
    background-color: ${colors.xxlGreen};
    border-color: ${colors.xxlGreen};
    cursor: pointer;
  }
`;

export const TextWrapper = styled.span`
  ${commonLinkStyles};
`;
