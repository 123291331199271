import React from "react";
import {
  UspList,
  UspItem,
  TopText,
  SmallText,
  Link,
  TextWrapper,
} from "./UspComponent.styled";
import type { UspTeaser } from "@xxl/content-api";
import { isNotNullOrUndefined } from "@xxl/common-utils";

type UspProps = {
  usps: UspTeaser[];
};

const UspContent = (topText?: string, smallText?: string): JSX.Element => (
  <>
    {isNotNullOrUndefined(topText) && <TopText>{topText}</TopText>}
    {isNotNullOrUndefined(smallText) && <SmallText>{smallText}</SmallText>}
  </>
);

export const UspComponent: React.FunctionComponent<UspProps> = ({ usps }) => {
  if (usps.length === 0) {
    return null;
  }
  return (
    <UspList>
      {usps.map((item, index) => {
        const { smallText, topText, url } = item;

        return isNotNullOrUndefined(smallText) ||
          isNotNullOrUndefined(topText) ? (
          <UspItem key={index}>
            {isNotNullOrUndefined(url) ? (
              <Link href={url}>{UspContent(topText, smallText)}</Link>
            ) : (
              <TextWrapper>{UspContent(topText, smallText)}</TextWrapper>
            )}
          </UspItem>
        ) : null;
      })}
    </UspList>
  );
};
